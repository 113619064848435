import React from 'react'
import FlightIcon from '@mui/icons-material/Flight';
import "./airportflight.css";

export default function AirportFlight(props) {
    return (
        <div
            onClick={() => {
                props.setFocusedOption({
                    type: "flights",
                    id: props.flight.id,
                })
                props.map.setView([props.flight.currentLocation.latitude, props.flight.currentLocation.longitude])
            }}
            key={props.flight.id + "card"}
            className="flight">
            <div className="data-field left">
                <div >{props.flight["flight-number"]}</div>
                <h1 className="value">{props.flight.departure.iata}</h1>
                <div className="description">{props.flight.departure.name}</div>
            </div>
            <div className="icon">
                <FlightIcon />
            </div>
            <div className="data-field right">
            <div>{props.flight["flight-number"]}</div>
                <h1 className="value">{props.flight.arrival.iata}</h1>
                <div className="description">{props.flight.arrival.name}</div>
            </div>
        </div>
    )
}
