import React, { useRef, useEffect, useState } from 'react'
import FlightIcon from '@mui/icons-material/Flight';
import "./flightprogress.css"

export default function FlightProgress(props) {
    const progressLineRef = useRef(null)
    const wrapperRef = useRef(null)
    const [progressCoords, setProgressCoords] = useState([0, 0])

    useEffect(() => {
        if (progressLineRef.current && wrapperRef.current) {
            const lengthProgress = progressLineRef.current.getTotalLength()
            progressLineRef.current.style["stroke-dasharray"] = lengthProgress
            progressLineRef.current.style["stroke-dashoffset"] = lengthProgress * (1 - props.progress)

            const heightFactor = wrapperRef.current.clientHeight / 7
            const widthFactor = wrapperRef.current.clientWidth / lengthProgress

            const falseFactorSVGx = props.progress > 0.2 ? props.progress * 7 : props.progress * 50;
            const falseFactorSVGy = (() => {
                if (props.progress > 0.8) {
                    return 21 * props.progress - 16;
                } else {
                    if(props.progress > 0.03){
                        return 0;
                    } else {
                        return 4;
                    }
                }
            })()

            const svgPos = progressLineRef.current.getPointAtLength(lengthProgress * props.progress)

            const positionBefore = progressLineRef.current.getPointAtLength(lengthProgress * props.progress - 0.1)
            const positionAfter = progressLineRef.current.getPointAtLength(lengthProgress * props.progress + 0.1)
            const heightDifference = positionBefore.y - positionAfter.y

            const pitch = -Math.atan(heightDifference / 0.2) * (180 / Math.PI)

            const coords = {
                x: (widthFactor * svgPos.x) - falseFactorSVGx,
                y: (wrapperRef.current.clientHeight - (heightFactor * svgPos.y) - 17) - falseFactorSVGy,
                rot: pitch
            }
            setProgressCoords(coords)

        }
    }, [props])
    const ProgressPlane = () => {
        const style = { bottom: `${progressCoords.y}px`, left: `${progressCoords.x}px`, transform: `rotate(${90 + progressCoords.rot}deg)` }

        return (
            <div style={style} className="progressplane-wrapper" >
                <FlightIcon />
            </div>
        )
    }

    return (
        <div ref={wrapperRef} className="progress">
            <ProgressPlane />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 65 5">
                <path d="M0 4C3 4 6 0 9 0L55 0C58 0 61 4 64 4" stroke="#747774" strokeWidth="0.5" fill="none" />
                <path ref={progressLineRef} className="flight-progress" d="M0 4C3 4 6 0 9 0L55 0C58 0 61 4 64 4" stroke="#747774" strokeWidth="0.5" fill="none" />
            </svg>
        </div>
    )
}
