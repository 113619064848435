import React, { useRef, useState } from 'react'
import Card from '../components/slide-card/card';
import Map from '../components/map/map'

export default function MapRoute() {

    const [focusedOption, setFocusedOption] = useState(null)

    const mapRef = useRef()

    return (
        <>
            <Card mapRef={mapRef} focusedOption={focusedOption} setFocusedOption={setFocusedOption} show={focusedOption != null} />
            <Map ref={mapRef} setFocusedOption={setFocusedOption} focusedOption={focusedOption} />
        </>
    )
}
