import React, { Component } from 'react'
import axios from "axios"
import config from "../../config/config.json"
import "./gates.css"
import { GatesResponse } from './gatesResponse'

export default class Gates extends Component {

    constructor() {
        super()
        this.state = {
            gateResponse: null,
            identInput: "LH",
            error: null,
            isLoading: false
        }
    }

    async handleSubmit(e) {
        e.preventDefault()
        try {
            this.setState({
                ...this.state,
                isLoading: true
            })
            const apiResponse = await axios.get(
                `${config['API-URL']}/telex/arrGate`,
                {
                    params: {
                        ident: this.state.identInput
                    }
                }
            )
            this.setState({
                ...this.state,
                gateResponse: apiResponse.data,
                error: null,
                isLoading: false
            })
        } catch (error) {
            this.setState({
                ...this.state,
                error: error.response?.data.error || error.message,
                isLoading: false
            })
        }
    }
    handleIdentChange(e) {
        const value = e.target.value
        if (value.startsWith("LH")) {
            this.setState({
                ...this.state,
                identInput: value
            })
        } else {
            this.setState({
                ...this.state,
                identInput: "LH"
            })
        }
    }
    handleIcaoChange(e) {
        const value = e.target.value
        this.setState({
            ...this.state,
            icaoInput: value.toUpperCase()
        })
    }

    render() {
        return (
            <>
                <div className="box">
                    <h1 style={{ textAlight: "center" }}>LH - Flightstatus</h1>
                    <form onSubmit={e => this.handleSubmit(e)}>
                        <p>Flight number</p>
                        <input type="text" value={this.state.identInput} onChange={e => this.handleIdentChange(e)} />
                        <button className={this.state.isLoading ? "loading" : ""}>
                            <p className='btn-text'>search</p>
                        </button>
                    </form>
                    <div className="error">{this.state.error}</div>
                    {this.state.gateResponse && <GatesResponse {...this.state.gateResponse} />}
                </div>
                <div className="background" />
            </>
        )
    }
}
