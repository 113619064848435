import React from 'react'
import dayjs from "dayjs"

export const GatesResponse = ({ gate, position, apiGate, _flight }) => {
    console.log(_flight)

    const Warning = ({ name, description }) => (<div
        className='block'
        style={{ "display": "flex", flexDirection: "column" }}
    >
        <div className="heading">Warning</div>
        <div className="data-field full">
            <h1 className="value">{name}</h1>
            <div className="description">{description}</div>
        </div>
    </div>)

    return (

        <div className='info-card'>

            {!apiGate && _flight && <Warning
                name="The provided Gate may not be accurate!"
                description="Please be patient that if an gate is displayed, it might be wrong" />
            }

            {_flight ?
                <div>
                    <div
                        className="block gates-container"
                        style={{ "display": "flex", "flexDirection": "column" }}>
                        <div className="heading">Airport information</div>
                        <div className="main-field" >
                            <div className="data-field left">
                                <h1 className="value">{_flight.Departure.AirportCode}</h1>
                                <div className="description">DEP</div>
                            </div>
                            <div className="data-field right">
                                <h1 className="value">{_flight.Arrival.AirportCode}</h1>
                                <div className="description">ARR</div>
                            </div>
                            <div className="data-field left">
                                <h1 className="value">{`${_flight.Departure.Terminal?.Gate || "X"}`}</h1>
                                <div className="description">Departure Gate</div>
                            </div>
                            <div className="data-field right">
                                <h1 className="value">{`${gate || "X"}, ${position || "X"}`}</h1>
                                <div className="description">Arrival Gate, Position</div>
                            </div>
                            <div className="data-field left">
                                <h1 className="value">{dayjs(_flight.Departure.ScheduledTimeUTC.DateTime).format("HH:mm") + "Z"}</h1>
                                <div className="description">{_flight.Departure.TimeStatus.Definition}</div>
                            </div>
                            <div className="data-field right">
                                <h1 className="value">{dayjs(_flight.Arrival.ScheduledTimeUTC.DateTime).format("HH:mm") + "Z"}</h1>
                                <div className="description">{_flight.Arrival.TimeStatus.Definition}</div>
                            </div>
                        </div>

                        <div
                            className="block"
                            style={{ "display": "flex", "flexDirection": "column" }}>
                            <div className="heading">Aircraft information</div>
                            <div className="main-field" >
                                <div className="data-field left">
                                    <h1 className="value">{_flight.Equipment.AircraftCode}</h1>
                                    <div className="description">Aircraft type</div>
                                </div>
                                <div className="data-field right">
                                    <h1 className="value">{_flight.Equipment.AircraftRegistration}</h1>
                                    <div className="description">Aircraft registration</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                    : <Warning name={"No data found"} description="" /> }

                </div>

    )
}
