import { CircularProgress } from '@mui/material';
import React from 'react'
import AirportFlight from './airport-flight';

export default function Airport(props) {
    if (props.info && props.info.arrivals) {
        return (
            <div>
                <div className="block">
                    <div className="heading">Airport information</div>
                    <div className="data-field left">
                        <div className="value">{props.info.name}</div>
                        <div className="description">Airport name</div>
                    </div>
                    <div className="data-field right">
                        <div className="value">{props.info.iata}</div>
                        <div className="description">Airport iata</div>
                    </div>
                    <div style={{gridColumn: "1 / 3", fontSize: "15px", fontFamily: "custom-text"}} className="data-field left">
                        <div className="value">{props.info.metar}</div>
                    </div>
                </div>
                <div style={{ display: "block" }} className="block">
                    <div className="heading">Outbounds</div>

                    {props.info.departures.map(flight => (
                        <AirportFlight flight={flight} setFocusedOption={props.setFocusedOption} map={props.map} />
                    ))}

                </div>
                <div style={{ display: "block" }} className="block">
                    <div className="heading">Inbounds</div>

                    {props.info.arrivals.map(flight => (
                        <AirportFlight flight={flight} setFocusedOption={props.setFocusedOption} map={props.map} />
                    ))}

                </div>
            </div>
        )
    } else {
        return (
            <div style={{ "position": "absolute", "top": "50%", "right": "50%", "transform": "translate(50%, 50%)" }}>
                <CircularProgress />
            </div>
        );
    }

}
