import React, { useEffect } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Polyline, useMap } from 'react-leaflet';
import { v4 as uuidv4 } from 'uuid';
import L from "leaflet"
import "leaflet.geodesic"
import "leaflet-rotatedmarker"
import "./map.css";
import config from "../../config/config.json"

function GeodistLine(props) {
    const map = useMap();
    useEffect(() => {
        const start = new L.LatLng(props.start[0], props.start[1])
        const end = new L.LatLng(props.end[0], props.end[1])
        const geodesicLeaflet = new L.Geodesic([start, end], props.pathOptions)

        const leafletLine = geodesicLeaflet.addTo(map);

        return () => leafletLine.remove();
    }, [map, props.end, props.start, props.pathOptions])
    return null;
}


class Map extends React.Component {
    constructor(props) {
        super(props)
        this.setFocusedOption = props.setFocusedOption
        this.state = {
            flights: [],
            airports: [],
            focusedOption: props.focusedOption
        }
        this.customMapElements = []
    }
    componentDidMount() {
        this.fetchFlights()
        this.startRefresh()
    }
    startRefresh() {
        this.refreshInterval = setInterval(async () => {
            await this.fetchFlights()
        }, 10000)
        return;
    }
    static getDerivedStateFromProps(e) {
        if (e) {
            return { focusedOption: e.focusedOption }
        }
    }

    fetchFlights = async () => {
        const responseFlights = await axios.get(`${config["API-URL"]}/flights`)
        var activeFlights = []
        for (const flightInfo of Object.entries(responseFlights.data)) {
            activeFlights.push(flightInfo[1])
        }
        this.setState({ flights: activeFlights })

        const responseAirports = await axios.get(`${config["API-URL"]}/airports`)
        var activeAirports = []
        for (const airportInfo of Object.entries(responseAirports.data)) {
            activeAirports.push(airportInfo[1])
        }
        this.setState({ airports: activeAirports })

        return;
    }
    getSnapshotBeforeUpdate(nextProps, nextState) {
        if (nextState.focusedOption !== this.state.focusedOption) {
            this.customMapElements.forEach(leafletEl => {
                this.map.removeLayer(leafletEl)
            })
        }
        return null;
    }

    toRad(Value) {
        return Value * Math.PI / 180;
    }
    calcCrow([lat1, lon1], [lat2, lon2]) {
        var R = 6371; // km
        var dLat = this.toRad(lat2 - lat1);
        var dLon = this.toRad(lon2 - lon1);
        lat1 = this.toRad(lat1);
        lat2 = this.toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }
    getCurvePoints(from, to) {
        return [[0, 0], [1, 1]];
    }

    render() {
        const MapHook = () => {
            this.map = useMap()
            return null
        }
        if (this.state.focusedOption) {
        }
        return (
            <>
                <MapContainer zoomControl={false} center={[48, 8]} zoom={5} >
                    <MapHook />
                    <TileLayer
                        attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
                        url={"https://api.mapbox.com/styles/v1/" + config.MAPBOX_NAME + "/" + config.MAPBOX_STYLE_ID + "/tiles/256/{z}/{x}/{y}@2x?access_token=" + config.MAPBOX_ACCES_TOKEN}
                    />
                    {this.state.flights.map(f => (
                        <>
                            <Marker
                                eventHandlers={{
                                    click: () => {
                                        if (window.innerHeight > 400) {
                                            this.setFocusedOption({
                                                type: 'flights',
                                                id: f.id
                                            })
                                        }
                                    },
                                }}
                                key={f.id}
                                position={[f.currentLocation.latitude, f.currentLocation.longitude]}
                                icon={L.divIcon({
                                    className: 'custom-div-icon',
                                    html: `<div class='plane-icon'><img style='transform: rotate(${f.currentLocation.heading}deg);' src='img/plane.png'></img></div>`,
                                    iconSize: [30, 42],
                                    iconAnchor: [15, 42]
                                })}

                            />
                            {this.state.focusedOption &&
                                this.state.focusedOption.type === "flights" &&
                                f.id === this.state.focusedOption.id &&
                                <>
                                    {/* From Departure to Path beginning */}
                                    {this.calcCrow([f.departure.latitude, f.departure.longitude], [f.history[0].latitude, f.history[0].longitude]) > 5 &&
                                        <GeodistLine
                                            start={[f.departure.latitude, f.departure.longitude]}
                                            end={[f.history[0].latitude, f.history[0].longitude]}
                                            pathOptions={{
                                                color: "#969896"
                                            }}
                                        />
                                    }


                                    {/* Flown Path */}
                                    <Polyline
                                        key={f.id + "-Line"}
                                        positions={[...f.history.map(h => [h.latitude, h.longitude])]}
                                        pathOptions={{
                                            color: "#fff",
                                        }}
                                    />

                                    {/* From Current Location to arrival */}
                                    {this.calcCrow([f.currentLocation.latitude, f.currentLocation.longitude], [f.arrival.latitude, f.arrival.longitude]) > 5 &&
                                        <GeodistLine
                                            start={[f.currentLocation.latitude, f.currentLocation.longitude]}
                                            end={[f.arrival.latitude, f.arrival.longitude]}
                                            pathOptions={{
                                                color: "#969896"
                                            }}
                                        />
                                    }
                                </>
                            }


                        </>
                    ))}
                    {this.state.airports.map(airport => (
                        <Marker
                            icon={new L.Icon({
                                iconUrl: "/img/airport.png",
                                iconSize: [25, 25],
                                iconAnchor: [15, 25],
                            })}
                            key={airport.icao}
                            position={[airport.latitude, airport.longitude]}
                            eventHandlers={{
                                click: () => {
                                    if (window.innerHeight > 400) {
                                        this.setFocusedOption({
                                            type: 'airports',
                                            id: airport.icao
                                        })
                                    }
                                },
                            }}
                        />
                    ))}
                    {this.state.focusedOption && this.state.focusedOption.type === "airports" && this.state.airports.find(ap => ap.icao === this.state.focusedOption.id) && this.state.airports.find(ap => ap.icao === this.state.focusedOption.id).arrivals.map(f => (
                        <Polyline
                            eventHandlers={{
                                click: () => {
                                    this.setFocusedOption({
                                        type: "flights", id: f.id
                                    })
                                    this.map.setView([f.currentLocation.latitude, f.currentLocation.longitude])
                                }
                            }}
                            key={uuidv4()}
                            positions={[[f.arrival.latitude, f.arrival.longitude], [f.departure.latitude, f.departure.longitude]]}
                            pathOptions={{
                                color: "#969896",
                            }}
                                />))}
                    {this.state.focusedOption && this.state.focusedOption.type === "airports" && this.state.airports.find(ap => ap.icao === this.state.focusedOption.id) && this.state.airports.find(ap => ap.icao === this.state.focusedOption.id).departures.map(f => (
                        <Polyline
                            eventHandlers={{
                                click: () => {
                                    this.setFocusedOption({
                                        type: "flights", id: f.id
                                    })
                                    this.map.setView([f.currentLocation.latitude, f.currentLocation.longitude])
                                }
                            }}
                            key={uuidv4()} positions={[[f.arrival.latitude, f.arrival.longitude], [f.departure.latitude, f.departure.longitude]]}
                            pathOptions={{
                                color: "#969896",
                            }
                            }
                        />))}
                </MapContainer>
            </>
        )
    }
}

export default Map;