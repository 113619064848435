import React, { useState, useEffect } from 'react'
import "./card.css"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';
import Flight from './flight/flight';
import axios from 'axios';
import Airport from './airport/airports';
import config from "../../config/config.json"

export default function Card(props) {
    const [info, setInfo] = useState(null);
    const [error, setError] = useState(null)
    const name = () => {
        if (info) {
            if (props.focusedOption.type === "airports") {
                return (info.icao)
            } else if (props.focusedOption.type === "flights") {
                return (info["flight-number"] + " / " + info.callsign)
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (props.show) {
                setError(null)
                setInfo(null)
                try {
                    const dataResponse = await axios.get(`${config["API-URL"]}/${props.focusedOption.type}/${props.focusedOption.id}`)
                    setInfo(dataResponse.data)

                } catch (error) {
                    setError(error)
                }

            }
        }
        if (props.focusedOption && props.show) {
            fetchData()
        } else {
            setInfo(null)
        }

    }, [props.focusedOption, props.show]);
    if (error) {
        return (
            <div className={props.show ? "show side-card" : "side-card"}>
                <div className="controller">
                    <button className="close" onClick={() => { props.setFocusedOption(null) }}><HighlightOffIcon /></button>
                    <h1>
                        Hmmm, something is wrong
                    </h1>
                </div>
                <div className="error-center">
                    <WarningIcon style={{ fontSize: "150px" }} />
                    <span>An error happened while fetching more data...</span>
                </div>
            </div>
        )
    }
    return (

        <div className={props.show ? "show side-card" : "side-card"}>
            {props.show && <>
                <div className="controller">
                    <button className="close" onClick={() => { props.setFocusedOption(null) }}><HighlightOffIcon /></button>
                    <h1>
                        {name()}

                    </h1>
                </div>
                {props.focusedOption.type === "flights" ? <Flight setFocusedOption={props.setFocusedOption} info={info} /> : <Airport map={props.mapRef.current.map} setFocusedOption={props.setFocusedOption} info={info} />}

            </>}

        </div>
    )
}
