import { Route, Routes } from "react-router-dom";

import React from 'react'
import MapRoute from "./Map";
import GatesRoute from "./gates";

export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<MapRoute />} />
            <Route path="/gate" element={<GatesRoute />} />
        </Routes>
    )
}
