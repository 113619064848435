import { CircularProgress } from '@mui/material';
import React from 'react'
import FlightProgress from './flightProgress';

export default function Airport(props) {
    if (props.info && props.info.callsign) {
        const distanceTotal = props.info.currentLocation.distance_remaining + props.info.currentLocation.distance_flown
        const progress = props.info.currentLocation.distance_flown / distanceTotal
        return (
            <div>
                <div style={{ marginTop: "20px" }}>
                    <FlightProgress progress={progress} />
                </div>
                <div
                    className="block"
                    style={{ "display": "flex", "flexDirection": "column" }}>
                    <div className="main-field" >
                        <div className="data-field left">
                            <h1 className="value">{props.info.departure.iata}</h1>
                            <div className="description">{props.info.departure.name}</div>
                        </div>
                        <div style={{ textAlign: "center" }} className="data-field">
                            <div className="value">{props.info.currentLocation.distance_remaining} NM</div>
                            <div className="description">Miles to go</div>
                        </div>
                        <div className="data-field right">
                            <h1 className="value">{props.info.arrival.iata}</h1>
                            <div className="description">{props.info.arrival.name}</div>
                        </div>
                    </div>
                </div>
                <div className="block" >
                    <div className="data-field left">
                        <div className="value">{props.info.currentLocation.departure_time.slice(0, 5)}</div>
                        <div className="description">{props.info.currentLocation.groundspeed > 30 ? "Actual" : "Estimated"} departure time</div>
                    </div>
                    <div className="data-field right">
                        <div className="value">{props.info.currentLocation.estimated_arrival_time}</div>
                        <div className="description">Estimated time arrival</div>
                    </div>

                </div>
                <div className="block">
                    <div className="heading">More {props.info.callsign} info</div>
                    {<div className="data-field">
                        <div className="description">Aircraft type</div>
                        <div className="value">{props.info.aircraft.name}</div>
                    </div>}
                    <div className="data-field">
                        <div className="description">Registration</div>
                        <div className="value">{props.info.aircraft.registration}</div>
                    </div>
                    <div className="data-field">
                        <div className="description">Pax</div>
                        <div className="value">{props.info.pax}</div>
                    </div>
                    <div className="data-field">
                        <div className="description">Cargo</div>
                        <div className="value">{props.info.cargo} kg</div>
                    </div>
                </div>
                <div className="block">
                    <div className="heading">Aircraft status</div>
                    <div className="data-field">
                        <div className="description">Altitude</div>
                        <div className="value">{Math.round(props.info.currentLocation.altitude / 100) * 100} ft</div>
                    </div>
                    <div className="data-field">
                        <div className="description">Heading</div>
                        <div className="value">{props.info.currentLocation.heading}°</div>
                    </div>
                    <div className="data-field">
                        <div className="description">Latitude</div>
                        <div className="value">{Math.round(props.info.currentLocation.latitude * 10000) / 10000}</div>
                    </div>
                    <div className="data-field">
                        <div className="description">Longitude</div>
                        <div className="value">{Math.round(props.info.currentLocation.longitude * 10000) / 10000}</div>
                    </div>
                    <div className="data-field">
                        <div className="description">Groundspeed</div>
                        <div className="value">{props.info.currentLocation.groundspeed} kts</div>
                    </div>
                    <div className="data-field">
                        <div className="description">Network</div>
                        <div className="value">{props.info.network}</div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ "position": "absolute", "top": "50%", "right": "50%", "transform": "translate(50%, 50%)" }}>
                <CircularProgress />
            </div>
        )
    }

}
